import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

import SEO from '../components/seo'

const IndustriesPage = () => (
  <StaticQuery query={industriesQuery} render={data => {
    const { title, subTitle, image } = data.markdownRemark.frontmatter
    return (
      <>
        <SEO title="Industries | BluePes" keywords={[`bluepes`, `industries`, `IT`]} />
        <BackgroundImage
          fluid={image.childImageSharp.fluid}
          className='background-image-industries general-page-background'
        >
          <div className="general-page-header wrapper">
            <h1 className="general-page-title">{title}</h1>
            <p className="general-page-description">
              {subTitle}
            </p>
          </div>
        </BackgroundImage>
        <section className="industries-page">
          <div className="general-page-wrapper">
            <div className="industries-wrapper wrapper">
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <article className="industry-item" key={node.id}>
                  <a className="industry-item-link">
                    <div className="industry-item-img">
                      <Img
                        fluid={node.frontmatter.image.childImageSharp.fluid}
                        alt={node.frontmatter.title} />
                    </div>
                    <div className="industry-item-info">
                      <h1 className="industry-item-header">
                        {node.frontmatter.title}
                      </h1>
                      <div
                        className="industry-item-description"
                        dangerouslySetInnerHTML={{ __html: node.html }} />
                    </div>
                  </a>
                </article>
              ))}
            </div>
          </div>
        </section>
      </>
    )
  }}
  />
)

export const industriesQuery = graphql`
  query {
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
      filter: {fileAbsolutePath: {regex: "/content/industries/"}}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            order
            image {
              childImageSharp {
                fluid(
                  maxWidth: 715, 
                  quality: 85, 
                  srcSetBreakpoints: [344, 448, 544, 608, 712]
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    markdownRemark(
      fileAbsolutePath: {regex: "/partial/general-pages-header/general-industries-header.md/"}
    ) {
      frontmatter {
        title
        subTitle
        image {
          childImageSharp {
            fluid(
              maxWidth: 1920, 
              quality: 80,
              srcSetBreakpoints: [375, 480, 768, 1024, 1440, 1920]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default IndustriesPage
